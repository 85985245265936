<template>
    <div>
        <div class="receipt-wrapper">
            <div class="receipt-header">
                <div class="d-flex flex-column align-items-center">
                    <h1>{{ storeName }}</h1>
                    <p>{{ formattedAddress }}</p>
                </div>
                <div class="d-flex flex-column align-items-center receipt-header">
                    <p>{{ formattedReceiptDate }}</p>
                    <p>Kvittonummer: {{ receiptNumber }}</p>
                </div>
            </div>

            <div class="receipt-lines-wrapper">
                <div v-for="line in receiptLines" :key="line.index" class="row receipt-line">
                    <div class="col-8 d-flex flex-column">
                        {{ line.quantity }} {{ line.unitName}} {{ line.articleName }}
                    </div>
                    <div class="col-4 d-flex flex-column align-items-end">
                        {{ this.formattedAmount(line.subTotalAmount) }}
                    </div>
                </div>
            </div>
            
            <div class="d-flex flex-column align-items-end receipt-totals">
                <p>Totalt: {{ this.formattedAmount(totalAmount) }}</p>
                <p v-for="vatRate in this.vatRates" :key="vatRate[0]">
                    Moms ({{ this.formattedPercentage(vatRate[0]) }}): {{ this.formattedAmount(vatRate[1]) }}
                </p>
            </div>

            <div class="d-flex flex-column align-items-end receipt-footer">
                <p>
                    Betalmetod: {{ localizedPaymentMethod }}
                </p>
                <p>
                    Kort: {{ cardVendor }} {{ this.cardNumberLastFour }}
                </p>
            </div>
            
            <div class="d-flex flex-column align-items-end receipt-download-button">
                <a :href="publicUrl" target="_blank" class="btn btn-outline-primary text-right">Ladda ner kvittot</a>
            </div>
        </div>
    </div>
</template>

<script>
import { getReceipt } from '@/firebase'
import { useRoute } from 'vue-router'

export default {
    name: "ReceiptView",
    data() {
        return {
            cardNumberLastFour: null,
            cardVendor: null,
            currencyCode: null,
            paymentMethod: null,
            publicUrl: null,
            receiptDate: null,
            receiptLines: [],
            receiptNumber: null,
            storeAddressLine1: null,
            storeAddressLine2: null,
            storePostalCode: null,
            storeCity: null,
            storeName: null,
            totalAmount: null,

            heroImageWidth: 600,
            heroImageHeight: 300,
            circleRadius: 16,
        }
    },
    async mounted() {
        try {
            const route = useRoute()
            const phoneNumber = route.params.phoneNumber
            const receiptId = route.params.receiptId
            const receipt = await getReceipt(phoneNumber, receiptId)

            this.cardNumberLastFour = receipt.cardNumberLastFour
            this.cardVendor = receipt.cardVendor
            this.currencyCode = receipt.currencyCode
            this.paymentMethod = receipt.paymentMethod
            this.publicUrl = receipt.publicUrl
            this.receiptDate = receipt.receiptDate
            this.receiptLines = receipt.receiptLines
            this.receiptNumber = receipt.receiptNumber
            this.storeAddressLine1 = receipt.storeAddressLine1
            this.storeAddressLine2 = receipt.storeAddressLine2
            this.storePostalCode = receipt.storePostalCode
            this.storeCity = receipt.storeCity
            this.storeName = receipt.storeName
            this.totalAmount = receipt.totalAmount
        } catch (error) {
            console.error(error)
        }
    },
    methods: {
        formattedAmount(amount) {
            return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amount)
        },
        formattedPercentage(percent) {
            return new Intl.NumberFormat('sv-SE', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(percent)
        },
    },
    computed: {
        formattedAddress() {
            if (
                !this.storeAddressLine1 &&
                !this.storeAddressLine2 &&
                !this.storePostalCode &&
                !this.storeCity
            ) return ''

            const postalCity = [this.storePostalCode, this.storeCity].join(' ')

            return [
                this.storeAddressLine1,
                this.storeAddressLine2,
                postalCity,
            ]
            .filter(e => { return e !== undefined })
            .join(', ')
        },
        formattedReceiptDate() {
            if (!this.receiptDate) {
                return ''
            } else {
                const formatter = Intl.DateTimeFormat('sv', {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                })
                const receiptDate = new Date(this.receiptDate.seconds * 1000)
                return formatter.format(receiptDate)
            }
        },
        localizedPaymentMethod() {
            switch (this.paymentMethod) {
                case 'card':
                    return 'Kort'
                case 'cash':
                    return 'Kontant'
            
                default:
                    return 'Okänt'
            }
        },
        vatRates() {
            let vatRates = {}
            this.receiptLines.forEach(l => {
                const vatRateKey = l.vatRate // this.formattedPercentage(l.vatRate)
                if (!vatRates[vatRateKey]) { vatRates[vatRateKey] = 0 }
                vatRates[vatRateKey] += l.subTotalAmount * l.vatRate
            })
            return Object.entries(vatRates).sort((a, b) => { return a[1] > b[1] })
        },
        circleInterval() {
            return this.circleRadius * 2.5
        },
        heroImageMaskHeight() {
            return this.heroImageHeight - this.circleRadius
        }
    }
}
</script>

<style>
p {
    margin: 0;
}
.receipt-hero-image {
    height: 200px;
}
.receipt-wrapper {
    margin: 0 auto;
    width: 25em;
    font-family: monospace;
    padding-top: 4em;
}
.receipt-header {
    padding: 1em 0;
}
.receipt-lines-wrapper {
    border-bottom: 1px dashed black;
}
.receipt-footer {
    padding: 2em 0;
}
.receipt-download-button {
    padding-bottom: 2em;
}
.receipt-campaign-button {
    text-transform: uppercase;
    background: #ddd;
    padding: 1em;
    border-radius: 1em;
    text-align: center;
}
.receipt-campaign-button-logo {
    display: block;
    height: 2.5em;
    width: 2.5em;
    background: peru;
    border-radius: 0.4em;
    background: url('/images/logo-image.png ');
    background-size: contain;
}


.payl-color {
    color: rgb(32, 183, 230);
}
.btn {
  border-color: rgb(32, 183,248);
  background:rgb(32, 183,248);
}
.btn:hover {
  border-color: rgb(28, 161, 218);
  background:rgb(28, 161, 218);
}
.btn-outline-primary {
  background: white;
  color: rgb(32, 183,248);
}
.progress-bar {
  background:rgb(32, 183,248);
}
.btn-outline-primary {
  color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:focus-visible {
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:active {
  color: #fff;
  background-color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(32 183 248 / 50%);
}
</style>